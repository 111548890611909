import { Button, Card, Modal, notification, Space } from 'antd';
import { arrayOf, bool, func, shape } from 'prop-types';
import React, { Suspense, useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BudgetTableContainer from '../../../components/admin/Budget/BudgetTable/BudgetTableContainer';
import './Budget.scss';
import Title from 'antd/es/typography/Title';
import PresupuestoForm from 'components/forms/PresupuestoForm';
import { useForm } from 'antd/es/form/Form';
import useRequest from 'hooks/useRequest';

const defaultModalProps = {
  children: null,
  title: 'Error',
  open: false,
};

/**
 * @param {Object} props - Props del Budget
 * @param {Array} props.budget - Arreglo con los presupuestos
 * @param {function} props.refreshBudgets - Función que actualiza los presupuestos
 */

function Budget(props) {
  const {
    budgets,
    refreshBudgets,
    handleOpenModal,
    modalOpen,
    modalAtributes,
    ModalComponent,
  } = props;

  const navigate = useNavigate();

  const [modalProps, setModalProps] = useState(defaultModalProps);
  const [form] = useForm();
  const { request, data, errors, isLoading } = useRequest();

  const savePresupuesto = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      const cliente = values.cliente;
      const result = await request('/presupuestos', 'POST', {
        idClient: cliente.id,
        otherBudgetId: values.otherBudgetId,
      });
      // notification.success({
      //   message: 'Presupuesto creado exitosamente',
      //   description: result.message,
      // });
      navigate(`/admin/budget/${result.id}/composer/${result.document_id}`);
      setModalProps(defaultModalProps);
    } catch (error) {
      notification.error({
        message: 'Error al guardar el presupuesto',
        description: error.message,
      });
    }
  };

  const openPresupuestoForm = () => {
    setModalProps({
      open: true,
      children: (
        <PresupuestoForm
          loading={isLoading('/presupuestos')}
          form={form}
          onFinish={savePresupuesto}
        />
      ),
      title: 'Nuevo Presupuesto',
    });
  };

  const closeModal = () => {
    setModalProps(defaultModalProps);
  };

  return (
    <div className="grid grid-cols-3 gap-4">
      <Modal footer={false} onCancel={closeModal} {...modalProps} />
      <Card className="welcome col-span-3">
        <div className=" flex flex-col md:flex-row items-center md:justify-between">
          <Title level={3}>
            <span className=" text-white">Tabla de presupuestos</span>
          </Title>
          <Space>
            <Link to={'add-form'} className="font-large font-primary">
              <Button type="dashed">
                Generar Presupuesto (Sistema antiguo)
              </Button>
            </Link>
            <Button type="primary" onClick={openPresupuestoForm}>
              Generar Presupuesto
            </Button>
          </Space>
        </div>
      </Card>
      <Card className="mb-2 col-span-3">
        <BudgetTableContainer
          budgets={budgets}
          refreshBudgets={refreshBudgets}
        />
      </Card>
      <Modal
        footer={modalAtributes.footer}
        open={modalOpen}
        onCancel={() => handleOpenModal(false)}
        title={modalAtributes.title}
        width={600}
      >
        <Suspense fallback={<div>Cargando...</div>}>
          {ModalComponent && <ModalComponent setReload={refreshBudgets} />}
        </Suspense>
      </Modal>
    </div>
  );
}

Budget.propTypes = {
  budgets: arrayOf(shape()).isRequired,
  refreshBudgets: func.isRequired,
  ModalComponent: shape({}),
  setModalOptions: shape({}).isRequired,
  handleOpenModal: func.isRequired,
  modalOpen: bool.isRequired,
  modalAtributes: shape({}).isRequired,
};
Budget.defaultProps = {
  ModalComponent: null,
};

export default Budget;
