import React from 'react';
import { Col, Form, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { API_PATH } from 'config';
import TextArea from 'antd/es/input/TextArea';

const ImageModule = ({ restField, name, normFile, handleImageChange }) => (
  <>
    <Col xs={24}>
      <Form.Item
        {...restField}
        name={[name, 'ant_image']}
        label="Imagen"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[
          {
            required: true,
            message: 'Por favor suba una imagen',
          },
        ]}
      >
        <Upload
          action={`${API_PATH}/upload/file/presupuesto`}
          listType="picture-card"
          maxCount={1}
          onChange={(info) => handleImageChange(info, name)}
        >
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        </Upload>
      </Form.Item>
    </Col>
    <Col xs={24}>
      <Form.Item {...restField} name={[name, 'caption']} label="Pie de foto">
        <TextArea rows={2} placeholder="Pie de foto" />
      </Form.Item>
    </Col>
  </>
);

export default ImageModule;
