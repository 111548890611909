import React from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import ComposerHeader from 'PDF/ComposerComponents/ComposerHeader';
import ComposerFooter from 'PDF/ComposerComponents/ComposerFooter';
import PresupuestoTableItems from './PresupuestoTableItems';
import CustomerInfo from 'PDF/ComposerComponents/CustomerInfo';

const replaceVariables = (text, variablesMap) => {
  return (
    text &&
    text.replace(/{(\w+)}/g, (_, key) => variablesMap[key] || `{${key}}`)
  );
};

const PresupuestoViewer = ({ items, variablesMap, config, colors, id }) => {
  let imageCounter = 1;

  const styles = StyleSheet.create({
    page: {
      paddingTop: 130,
      paddingLeft: 30,
      paddingRight: 30,
      paddingBottom: 70,
    },
    section: {
      marginBottom: 10,
    },
    primaryTitle: {
      fontSize: 18,
      fontWeight: 'bold',
      color: colors.primary ?? '#4CAF50',
      marginBottom: 10,
      marginTop: 20,
      textTransform: 'uppercase',
    },
    secondaryTitle: {
      fontSize: 14,
      fontWeight: 'bold',
      color: '#000',
      marginBottom: 10,
      marginTop: 15,
      textTransform: 'uppercase',
    },
    text: {
      fontSize: 12,
      marginBottom: 10,
      lineHeight: 1.5,
    },
    imageContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 10,
    },
    image: {
      maxWidth: '100%',
      maxHeight: 200,
      height: 'auto',
    },
    caption: {
      fontSize: 10, // Two points smaller than the default font size
      fontStyle: 'italic',
      textAlign: 'center',
      marginTop: 4,
    },
  });
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <ComposerHeader config={config} colors={colors} id={id} />
        {items?.map((item, index) => (
          <View key={index} style={styles.section} wrap={false}>
            {item.titulo_type === 'principal' && (
              <Text style={styles.primaryTitle}>
                {replaceVariables(item.titulo, variablesMap)}
              </Text>
            )}
            {item.titulo_type === 'secundario' && (
              <Text style={styles.secondaryTitle}>
                {replaceVariables(item.titulo, variablesMap)}
              </Text>
            )}
            <Text style={styles.text}>
              {item?.description &&
                replaceVariables(item.description, variablesMap)}
            </Text>
            {item.type === 'itemTable' && (
              <PresupuestoTableItems items={item.itemsTable} colors={colors} />
            )}
            {item.type === 'image' && (
              <View style={styles.imageContainer} wrap={false}>
                <Image
                  style={styles.image}
                  source={item?.image?.path}
                  resizeMode="contain"
                />
                <Text style={styles.caption}>
                  {`Imagen ${imageCounter}: ${replaceVariables(
                    item.caption,
                    variablesMap
                  )}`}
                </Text>
                {imageCounter++}
              </View>
            )}
            {item.type === 'customerInfo' && (
              <CustomerInfo
                colors={colors}
                customerInfo={item.customerInfo}
                variablesMap={variablesMap}
              />
            )}
          </View>
        ))}
        <ComposerFooter config={config} colors={colors} />
      </Page>
    </Document>
  );
};

export default PresupuestoViewer;
