import { Modal, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import ActividadForm from 'components/forms/ActividadForm';
import ActividadMantencionForm from 'components/forms/ActividadMantenciónForm';
import ActivityMaintanceForm from 'components/forms/ActivityMaintanceForm';
import EliminarForm from 'components/forms/EliminarForm';
import ImportActividadesForm from 'components/forms/ImportActividadesForm';
import { useAppApi, usePAppApi } from 'hooks/useAppApi';
import useRequest from 'hooks/useRequest';
import { createContext, useCallback, useContext, useState } from 'react';

const ActividadesContext = createContext(null);

const defaultModalProps = {
  children: null,
  title: 'Error',
  open: false,
};

export const ActividadesProvider = ({ children, id }) => {
  const [modalProps, setModalProps] = useState(defaultModalProps);

  const map = useCallback((result) => {
    return result;
  }, []);

  const {
    data,
    isLoading: loading,
    error,
    hasCalled,
    call: notFunctionalCall,
  } = useAppApi({
    baseUrl: '/actividad/clientes',
    mapResults: map,
  });

  const call = useCallback(
    async (id_client) => {
      const result = await notFunctionalCall({ extUrl: `/${id_client}` });

      return result;
    },
    [notFunctionalCall]
  );

  //   const { call: NFaddTenant } = usePAppApi({
  //     url: '/Actividades',
  //     mapResults: useCallback((data) => {
  //       console.log(data);
  //       return data;
  //     }, []),
  //   });
  const { request, data: reqData, errors, isLoading } = useRequest();
  const [isLoadingAdd, setIsLoadingAdd] = useState(false);
  const ADD_URL = '/actividad';

  const [form] = useForm();
  const add = useCallback(
    async (values) => {
      setIsLoadingAdd(true);
      try {
        await form.validateFields();
        if (values.tipo_intervalo?.length === 1) {
          let postValues = {
            ...values,
            tipo_intervalo: values.tipo_intervalo[0],
          };
          const response = await request(ADD_URL, 'POST', postValues);
          call(values?.id_cliente);
          setModalProps(defaultModalProps);
          setIsLoadingAdd(false);
          return response;
        } else {
          notification.error({
            message: 'Error al agregar la actividad',
          });
          setIsLoadingAdd(false);
        }
      } catch (error) {
        setIsLoadingAdd(false);
        notification.error({
          message: 'Error al agregar la actividad',
          description: error.message,
        });
      }
    },
    [request, form, call]
  );
  const edit = useCallback(
    async (values, id) => {
      try {
        // console.log(values, id, 'edit');
        await form.validateFields();
        if (values.tipo_intervalo?.length === 1) {
          let postValues = {
            ...values,
            tipo_intervalo: values.tipo_intervalo[0],
          };
          const response = await request(`${ADD_URL}/${id}`, 'PUT', postValues);
          call(values?.id_cliente);
          setModalProps(defaultModalProps);
          return response;
        } else {
          notification.error({
            message: 'Error al agregar la actividad',
          });
        }
      } catch (error) {
        notification.error({
          message: 'Error al editar la actividad',
          description: error.message,
        });
      }
    },
    [request, form, call]
  );
  const deleteActividad = async (id, id_cliente) => {
    const response = await request(`${ADD_URL}/${id}`, 'DELETE');
    setModalProps(defaultModalProps);
    call(id_cliente);
    return response;
  };
  const openAdd = async (id_cliente) => {
    setModalProps({
      open: true,
      children: (
        <ActividadMantencionForm
          form={form}
          isLoadingAdd={isLoadingAdd}
          onFinish={add}
          layout="vertical"
          initialValues={{
            tipo_intervalo: ['meses'],
            id_cliente: id_cliente,
            type: 'mantencion',
          }}
        />
      ),
      title: 'Agregar Actividad',
    });
  };
  const openEdit = async (values) => {
    setModalProps({
      open: true,
      children: (
        <ActividadMantencionForm
          form={form}
          onFinish={(valuesForm) => edit(valuesForm, values.id)}
          layout="vertical"
          initialValues={{
            ...values,
            Fechas: values?.Fechas?.map((fecha) => Number(fecha.mes)),
            tipo_intervalo: ['meses'],
          }}
          key={values.id}
        />
      ),
      title: 'Agregar Actividad',
    });
  };
  const openDelete = async (id, id_cliente) => {
    setModalProps({
      open: true,
      children: (
        <EliminarForm
          title="Eliminar Actividad"
          id={id}
          deleteRegister={(id) => deleteActividad(id, id_cliente)}
        />
      ),
      title: 'Agregar Actividad',
    });
  };
  const openImport = async (id_cliente) => {
    setModalProps({
      open: true,
      children: (
        <ImportActividadesForm
          id={id_cliente}
          onFinish={(data) => {
            call(id_cliente);
            setModalProps(defaultModalProps);
          }}
        />
      ),
      title: 'Importar Actividades',
      width: 700,
    });
  };

  return (
    <>
      <Modal
        footer={false}
        onCancel={() => setModalProps(defaultModalProps)}
        {...modalProps}
      />
      <ActividadesContext.Provider
        value={{
          call,
          id,
          loading,
          error,
          hasCalled,
          data,
          openAdd,
          isLoadingAdd: isLoading(ADD_URL),
          openEdit,
          openDelete,
          openImport,
        }}
      >
        {children}
      </ActividadesContext.Provider>
    </>
  );
};

export const useActividades = () => useContext(ActividadesContext);
