import React, { useEffect, useMemo, useState } from 'react';
import {
  Drawer,
  Form,
  Button,
  Input,
  InputNumber,
  Divider,
  Select,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useColeccion } from 'providers/ColeccionProvider';
import { formatMoneyNumber } from 'utils/formaters';

const { Option } = Select;

const PresupuestoItemsDrawer = ({
  visible,
  onClose,
  onSave,
  cliente,
  values,
  currentKey,
}) => {
  const [form] = Form.useForm();
  // console.log(currentKey, 'currentKey');
  // console.log(values, 'values');

  const { data: coleccion, call: callColeccion } = useColeccion();
  const [selectedColeccion, setSelectedColeccion] = useState({});
  const ascensores = useMemo(() => {
    return cliente?.Ascensores;
  }, [cliente]);

  useEffect(() => {
    callColeccion();
  }, [callColeccion]);

  useEffect(() => {
    if (values && values.items) {
      const currentItemsTable = values.items[currentKey]?.itemsTable || [];
      console.log(currentItemsTable);
      form.setFieldsValue({ items: currentItemsTable });
    }
  }, [values, form, currentKey]);

  const onFinish = (values) => {
    const itemsWithPosition = values.items.map((item, index) => ({
      ...item,
      position: index + 1,
    }));
    form.resetFields();
    onSave(itemsWithPosition);
    onClose();
  };

  const handleColeccionChange = (value, key) => {
    const selectedItem = selectedColeccion.ColeccionItems.find(
      (item) => item.id === value
    );

    if (selectedItem) {
      form.setFieldsValue({
        items: form.getFieldValue('items').map((item, index) => {
          if (index === key) {
            return {
              ...item,
              description: selectedItem.nombre,
              unitValue: selectedItem.precio
                ? parseFloat(selectedItem.precio)
                : null,
              help: {
                description: selectedItem.descripcion,
                price: selectedItem.precio,
                cost: selectedItem.costo,
                quantity: selectedItem.cantidad,
              },
            };
          }
          return item;
        }),
      });
    }
  };

  const handleCollectionSelect = (collectionId, key) => {
    const collection = coleccion.find((c) => c.id === collectionId);
    setSelectedColeccion(collection);
    form.setFieldsValue({
      items: form.getFieldValue('items').map((item, index) => {
        if (index === key) {
          return {
            ...item,
            coleccionItem: undefined, // Clear previous selection
          };
        }
        return item;
      }),
    });
  };

  const handleAscensorChange = (value, key) => {
    form.setFieldsValue({
      items: form.getFieldValue('items').map((item, index) => {
        if (index === key) {
          return {
            ...item,
            ascensor: value,
          };
        }
        return item;
      }),
    });
  };

  return (
    <Drawer title="Tabla de Ítems" width={720} onClose={onClose} open={visible}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.List name="items">
          {(fields, { add, remove }) => (
            <>
              {fields?.map(({ key, name, ...restField }) => {
                const itemHelp =
                  form.getFieldValue(['items', key, 'help']) || {};
                return (
                  <div key={key} className="flex flex-row">
                    <div className="grid grid-cols-6 w-full">
                      <Divider className="col-span-6">Item N°{key + 1}</Divider>
                      <div className="flex flex-row justify-between space-x-2 col-span-6">
                        <Form.Item
                          label="Colección"
                          className="w-full"
                          {...restField}
                          name={[name, 'coleccion']}
                        >
                          <Select
                            placeholder="Seleccionar colección"
                            onChange={(value) =>
                              handleCollectionSelect(value, key)
                            }
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {coleccion
                              .filter((c) => c.type === 'PRODUCTOS')
                              .map((c) => (
                                <Option key={c.id} value={c.id}>
                                  {c.nombre}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label="Ítem de la colección"
                          className="w-full"
                          {...restField}
                          name={[name, 'coleccionItem']}
                        >
                          <Select
                            placeholder="Seleccionar ítem de la colección"
                            onChange={(value) =>
                              handleColeccionChange(value, key)
                            }
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            disabled={!selectedColeccion.ColeccionItems}
                          >
                            {selectedColeccion.ColeccionItems &&
                              selectedColeccion.ColeccionItems.filter(
                                (item) => item.tipo_item === 'PRODUCTO'
                              ).map((item) => (
                                <Option key={item.id} value={item.id}>
                                  {item.nombre}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </div>
                      <Form.Item
                        {...restField}
                        name={[name, 'ascensor']}
                        className="col-span-2 mr-2"
                        rules={[
                          {
                            required: true,
                            message: 'Debe seleccionar un ascensor',
                          },
                        ]}
                      >
                        <Select
                          placeholder="Seleccionar ascensor"
                          onChange={(value) => handleAscensorChange(value, key)}
                          showSearch
                          mode="tags"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {ascensores?.map((ascensor) => (
                            <Option key={ascensor.id} value={ascensor.nombre}>
                              {ascensor.nombre}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        rules={[
                          {
                            required: true,
                            message: 'Debe ingresar una descripción del item',
                          },
                        ]}
                        name={[name, 'description']}
                        className="col-span-4"
                        help={itemHelp.description}
                      >
                        <Input placeholder="Descripción" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        rules={[
                          {
                            required: true,
                            message: 'Debe ingresar el valor unitario',
                          },
                        ]}
                        name={[name, 'unitValue']}
                        className="col-span-3 mr-2"
                        help={
                          itemHelp.price
                            ? `Precio: $${formatMoneyNumber(
                                itemHelp.price
                              )}, Costo: $${formatMoneyNumber(itemHelp.cost)}`
                            : ''
                        }
                      >
                        <InputNumber
                          prefix={'$'}
                          placeholder="Valor unitario"
                          className="w-full"
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'quantity']}
                        className="col-span-3"
                        rules={[
                          {
                            required: true,
                            message: 'Debe ingresar la cantidad',
                          },
                        ]}
                        help={
                          itemHelp.quantity
                            ? `Cantidad disponible: ${itemHelp.quantity}`
                            : ''
                        }
                      >
                        <InputNumber
                          className="w-full"
                          placeholder="Cantidad"
                        />
                      </Form.Item>
                    </div>
                    <MinusCircleOutlined
                      className="ml-2 text-lg text-red-600"
                      onClick={() => remove(name)}
                    />
                  </div>
                );
              })}
              <Form.Item>
                <Button
                  className="rounded-md text-white"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  type="primary"
                >
                  Añadir Item
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default PresupuestoItemsDrawer;
