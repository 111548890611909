import React from 'react';
import { ProgramadasProvider } from 'providers/Mantenciones/ProgramadasProvider';
import { ToDoProvider } from 'providers/Mantenciones/useToDo';
import { ActionsODTProvider } from 'providers/ActionsOdtProvider';
import { PDFStyleProvider } from 'providers/PDF/PDFStyleProvider';
import { CalendarActionsProvider } from 'providers/CalendarSolc/CalendarActionsProvider';
import { SolicitudesMantencionProvider } from 'providers/SolicitudesMantencionProvider';
import { OneMantencionProvider } from 'providers/OneMantencionProvider';
import { MaintanceToDoProvider } from 'providers/MaintanceToDoProvider';
import { AscensorEstadosProvider } from 'providers/AscensorEstadosProvider';
import { ObservacionAccionesProvider } from 'providers/ObservacionAccionesProvider';
import { ObservacionesProvider } from 'providers/ObservacionesProvider';
import { ObservacionesTypesProvider } from 'providers/ObservacionesTypesProvider';

const whitSpecProvider = (Component) => {
  const WrappedComponent = (props) => (
    <ProgramadasProvider>
      <ToDoProvider>
        <ActionsODTProvider>
          <PDFStyleProvider>
            <CalendarActionsProvider>
              <SolicitudesMantencionProvider>
                <OneMantencionProvider>
                  <MaintanceToDoProvider>
                    <AscensorEstadosProvider>
                      <ObservacionAccionesProvider>
                        <ObservacionesProvider>
                          <ObservacionesTypesProvider>
                            <Component {...props} />
                          </ObservacionesTypesProvider>
                        </ObservacionesProvider>
                      </ObservacionAccionesProvider>
                    </AscensorEstadosProvider>
                  </MaintanceToDoProvider>
                </OneMantencionProvider>
              </SolicitudesMantencionProvider>
            </CalendarActionsProvider>
          </PDFStyleProvider>
        </ActionsODTProvider>
      </ToDoProvider>
    </ProgramadasProvider>
  );

  // Asigna un displayName al componente envuelto
  WrappedComponent.displayName = `WithSpecProvider(${
    Component.displayName || Component.name || 'Component'
  })`;

  return WrappedComponent;
};

export default whitSpecProvider;
