import PDFOrderContainer from 'PDF/Order/PDFOrderContainer';
import { notification } from 'antd';

import { createContext, useContext } from 'react';

const ActionsODTContext = createContext(null);

export const ActionsODTProvider = ({ children }) => {
  const dowload = async (id) => {
    notification.open({
      duration: 30,
      message: `Descargar Orden de Trabajo N°${id}`,
      btn: <PDFOrderContainer idBudget={id} />,
    });
  };

  return (
    <ActionsODTContext.Provider
      value={{
        dowload,
      }}
    >
      {children}
    </ActionsODTContext.Provider>
  );
};

export const useActionsODT = () => useContext(ActionsODTContext);
