import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  Select,
  Card,
  Divider,
  Row,
  Col,
  Modal,
  Input,
} from 'antd';
import {
  MinusCircleOutlined,
  PlusOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import PresupuestoCollectionDrawer from 'components/PresupuestoCollectionDrawer';
import PresupuestoItemsDrawer from 'components/PresupuestoItemsDrawer';
import { v4 as uuidv4 } from 'uuid';
import Title from 'antd/es/typography/Title';
import PresupuestoViewer from 'PDF/PresupuestoComposer/PresupuestoViewer';
import VariablesModal from 'components/VariablesModal';
import CustomModule from './CustomModule';
import CollectionModule from './CollectionModule';
import ItemTableModule from './ItemTableModule';
import ImageModule from './ImageModule';
import CustomerInfoModule from './ClienteInfoModule';
import FormItemLabel from 'antd/es/form/FormItemLabel';
import { transformData } from 'utils/transformDataPresupuesto';

const { Option } = Select;

const Compositor = ({
  form,
  availableModules,
  variablesMap,
  documentElements,
  cliente,
  presupuesto_id,
}) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [itemTableDrawerVisible, setItemTableDrawerVisible] = useState(false);
  const [variablesModalVisible, setVariablesModalVisible] = useState(false);
  const [currentFieldKey, setCurrentFieldKey] = useState(null);
  const [deletedItems, setDeletedItems] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);

  useEffect(() => {
    if (documentElements) {
      form.setFieldsValue(transformData(documentElements));
    }
  }, [documentElements, form]);

  const handleTypeChange = (value, fieldKey) => {
    const items = form.getFieldValue('items');
    const updatedItems = items.map((item, index) => {
      if (index === fieldKey) {
        return { ...item, type: value };
      }
      return item;
    });

    form.setFieldsValue({ items: updatedItems });
    setCurrentFieldKey(fieldKey);

    const selectedModule = availableModules?.find(
      (module) => module.key === value
    );
    if (selectedModule) {
      if (selectedModule.key === 'collection') {
        setDrawerVisible(true);
      } else if (selectedModule.key === 'itemTable') {
        setItemTableDrawerVisible(true);
      }
    }
  };

  const handleTemplateSelect = (template) => {
    if (currentFieldKey !== null) {
      form.setFieldsValue({
        items: form.getFieldValue('items').map((item, index) => {
          if (index === currentFieldKey) {
            return {
              ...item,
              titulo: template.titulo,
              description: template.texto,
            };
          }
          return item;
        }),
      });
      setDrawerVisible(false);
      setItemTableDrawerVisible(false);
    }
  };

  const handleSaveItems = (items) => {
    if (currentFieldKey !== null) {
      form.setFieldsValue({
        items: form.getFieldValue('items').map((item, index) => {
          if (index === currentFieldKey) {
            return {
              ...item,
              itemsTable: items,
            };
          }
          return item;
        }),
      });
      setItemTableDrawerVisible(false);
    }
  };

  const handleRemove = (name) => {
    const items = form.getFieldValue('items');
    const itemToRemove = items.find((_, index) => index === name);
    setDeletedItems([...deletedItems, itemToRemove]);
    form.setFieldsValue({
      items: items.filter((_, index) => index !== name),
    });
  };

  const [items, setItems] = useState([]);
  const openPreview = () => {
    setPreviewVisible(true);
    setItems(form.getFieldValue('items'));
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleImageChange = (info, name) => {
    if (info.file.status === 'done') {
      const responseFile = info.file.response?.file;
      if (responseFile) {
        const items = form.getFieldValue('items');
        items[name].image = responseFile;
        form.setFieldsValue({ items });
      }
    }
  };

  const handleMove = (fromIndex, toIndex) => {
    const items = form.getFieldValue('items');
    const [movedItem] = items.splice(fromIndex, 1);
    items.splice(toIndex, 0, movedItem);

    const updatedItems = items.map((item, index) => ({
      ...item,
      position: index + 1,
    }));

    form.setFieldsValue({ items: updatedItems });
  };

  const renderModuleForm = (itemType, restField, name) => {
    switch (itemType) {
      case 'custom':
        return <CustomModule restField={restField} name={name} />;
      case 'collection':
        return <CollectionModule restField={restField} name={name} />;
      case 'itemTable':
        return <ItemTableModule form={form} name={name} />;
      case 'image':
        return (
          <ImageModule
            restField={restField}
            name={name}
            normFile={normFile}
            handleImageChange={handleImageChange}
          />
        );
      case 'customerInfo':
        return <CustomerInfoModule restField={restField} name={name} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <Modal
        title="Vista Previa"
        open={previewVisible}
        onCancel={() => setPreviewVisible(false)}
        width={1000}
      >
        <PresupuestoViewer
          id={presupuesto_id}
          items={items}
          variablesMap={variablesMap}
        />
      </Modal>
      <Card className="welcome mb-2">
        <div className="flex flex-col items-center md:flex-row md:justify-between">
          <Title level={3}>
            <span className="text-white">Compositor de Documentos</span>
          </Title>
          <div>
            <Button
              type="primary"
              onClick={openPreview}
              icon={<EyeOutlined />}
              className="ml-2"
            >
              Vista Previa
            </Button>
          </div>
        </div>
      </Card>
      <Form form={form} layout="vertical">
        <Form.List
          name="items"
          initialValue={[
            {
              id: uuidv4(),
              type: 'custom',
              titulo_type: 'principal',
              titulo: '',
              description: '',
            },
          ]}
        >
          {(fields, { add, remove }) => (
            <>
              <div className="-mx-2 flex flex-col items-center">
                {fields.map(({ key, name, ...restField }, index) => {
                  const itemType = form.getFieldValue(['items', key, 'type']);
                  return (
                    <div key={key} className="w-full px-2 mb-4">
                      <Card>
                        <Divider>Posición N°{index + 1}</Divider>
                        <Form.Item hidden name={[name, 'position']}>
                          <Input value={index + 1} disabled />
                        </Form.Item>
                        <Row gutter={16}>
                          <Col xs={24} md={12}>
                            <Form.Item
                              {...restField}
                              name={[name, 'type']}
                              label="Tipo de Elemento"
                              rules={[
                                {
                                  required: true,
                                  message: 'Seleccione un tipo de elemento',
                                },
                              ]}
                            >
                              <Select
                                placeholder="Seleccione el tipo de elemento"
                                onChange={(value) =>
                                  handleTypeChange(value, key)
                                }
                              >
                                {availableModules?.map((module) => (
                                  <Option key={module.key} value={module.key}>
                                    {module.label}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12}>
                            {itemType === 'itemTable' && (
                              <>
                                <FormItemLabel label="Acciones: " />
                                <Button
                                  className="mt-2"
                                  type="primary"
                                  block
                                  onClick={() => {
                                    setItemTableDrawerVisible(true);
                                    setCurrentFieldKey(key);
                                  }}
                                >
                                  Abrir
                                </Button>
                              </>
                            )}
                            {itemType === 'image' && null}
                            {itemType === 'customerInfo' && null}
                            {(itemType === 'custom' ||
                              itemType === 'collection') && (
                              <Form.Item
                                {...restField}
                                name={[name, 'titulo_type']}
                                label="Tipo de Título"
                                rules={[
                                  {
                                    required:
                                      itemType !== 'itemTable' &&
                                      itemType !== 'image',
                                    message: 'Seleccione el tipo de título',
                                  },
                                ]}
                              >
                                <Select placeholder="Seleccione el tipo de título">
                                  <Option value="principal">Principal</Option>
                                  <Option value="secundario">Secundario</Option>
                                </Select>
                              </Form.Item>
                            )}
                          </Col>
                          {renderModuleForm(itemType, restField, name)}
                        </Row>
                        <div className="flex justify-between">
                          <Button
                            icon={<ArrowUpOutlined />}
                            disabled={index === 0}
                            type="primary"
                            onClick={() => handleMove(index, index - 1)}
                          />
                          <Button
                            icon={<ArrowDownOutlined />}
                            type="primary"
                            disabled={index === fields.length - 1}
                            onClick={() => handleMove(index, index + 1)}
                          />
                          <MinusCircleOutlined
                            className="mt-2 text-lg text-red-600"
                            onClick={() => handleRemove(name)}
                          />
                        </div>
                      </Card>
                    </div>
                  );
                })}
              </div>
              <Form.Item>
                <Button
                  className="px-10"
                  onClick={() =>
                    add({
                      id: uuidv4(),
                      type: 'custom',
                      titulo_type: 'principal',
                      titulo: '',
                      description: '',
                    })
                  }
                  icon={<PlusOutlined />}
                  type="primary"
                >
                  Añadir Elemento
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
      <PresupuestoCollectionDrawer
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        onSelectTemplate={handleTemplateSelect}
      />
      <PresupuestoItemsDrawer
        values={form.getFieldValue()}
        cliente={cliente}
        visible={itemTableDrawerVisible}
        onClose={() => setItemTableDrawerVisible(false)}
        onSave={handleSaveItems}
        currentKey={currentFieldKey}
      />

      <VariablesModal
        visible={variablesModalVisible}
        onClose={() => setVariablesModalVisible(false)}
      />
    </div>
  );
};

export default Compositor;
