import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';

const formatNumber = (number) => {
  return new Intl.NumberFormat('es-CL', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);
};

const PresupuestoTableItems = ({ items, colors }) => {
  // Calcular el valor neto sumando los totales de cada ítem
  const neto = items.reduce(
    (acc, item) => acc + item.unitValue * item.quantity,
    0
  );
  // Calcular el IVA (19% del valor neto)
  const iva = neto * 0.19;
  // Calcular el total sumando el valor neto y el IVA
  const total = neto + iva;

  const styles = StyleSheet.create({
    table: {
      display: 'table',
      width: 'auto',
      marginBottom: 20,
    },
    tableRow: {
      flexDirection: 'row',
    },
    tableCell: {
      padding: 5,
      fontSize: 12,
    },
    tableRightCell: {
      padding: 5,
      fontSize: 12,
      textAlign: 'right',
    },
    header: {
      backgroundColor: colors.primary ?? '#4CAF50',
      color: 'white',
      fontWeight: 'bold',
    },
    footer: {
      fontWeight: 'bold',
    },
    evenRow: {
      backgroundColor: colors.secondary ?? '#b3dea6',
    },
  });

  return (
    <View style={styles.table}>
      <View style={[styles.tableRow, styles.header]}>
        <Text style={[styles.tableCell, { width: '5%' }]}>N°</Text>
        <Text style={[styles.tableCell, { width: '15%' }]}>Ascensor</Text>
        <Text style={[styles.tableCell, { width: '40%' }]}>Descripción</Text>
        <Text style={[styles.tableRightCell, { width: '15%' }]}>Val.Unit</Text>
        <Text style={[styles.tableRightCell, { width: '10%' }]}>Cant.</Text>
        <Text style={[styles.tableRightCell, { width: '15%' }]}>Total</Text>
      </View>
      {items?.map((item, index) => (
        <View
          key={item.key}
          style={[
            styles.tableRow,
            index % 2 !== 0 && styles.evenRow, // Apply evenRow style to even indices
          ]}
        >
          <Text style={[styles.tableCell, { width: '5%' }]}>{index + 1}</Text>
          <Text style={[styles.tableCell, { width: '15%' }]}>
            {item.ascensor}
          </Text>
          <Text style={[styles.tableCell, { width: '40%' }]}>
            {item.description}
          </Text>
          <Text style={[styles.tableRightCell, { width: '15%' }]}>
            $ {formatNumber(item.unitValue)}
          </Text>
          <Text style={[styles.tableRightCell, { width: '10%' }]}>
            {item.quantity}
          </Text>
          <Text style={[styles.tableRightCell, { width: '15%' }]}>
            $ {formatNumber(item.unitValue * item.quantity)}
          </Text>
        </View>
      ))}
      <View style={[styles.tableRow, styles.footer]}>
        <Text style={[styles.tableCell, { width: '70%' }]}>Valor Neto</Text>
        <Text style={[styles.tableRightCell, { width: '30%' }]}>
          $ {formatNumber(neto)}
        </Text>
      </View>
      <View style={[styles.tableRow, styles.footer]}>
        <Text style={[styles.tableCell, { width: '70%' }]}>IVA</Text>
        <Text style={[styles.tableRightCell, { width: '30%' }]}>
          $ {formatNumber(iva)}
        </Text>
      </View>
      <View
        style={[
          styles.tableRow,
          styles.footer,
          { backgroundColor: colors.primary ?? '#4CAF50', color: 'white' },
        ]}
      >
        <Text style={[styles.tableCell, { width: '70%' }]}>Total</Text>
        <Text style={[styles.tableRightCell, { width: '30%' }]}>
          ${formatNumber(total)}
        </Text>
      </View>
    </View>
  );
};

export default PresupuestoTableItems;
