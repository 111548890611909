import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import {
  getAccessTokenApi,
  getRefreshTokenApi,
  logout,
  refreshAccessTokenApi,
} from '../api/auth';
import { usePAppApi } from 'hooks/useAppApi';

// export const logoutAction = () => {
//   logout();
//   navigate('/login');
//   // window.location.href = '/login';
// };

const checkUserLogin = async (setUser) => {
  const accessToken = await getAccessTokenApi();
  if (!accessToken) {
    const refreshToken = await getRefreshTokenApi();

    if (!refreshToken) {
      logout();
      setUser({
        isLoading: false,
        user: null,
      });
    } else if (await refreshAccessTokenApi(refreshToken)) {
      setUser({
        isLoading: false,
        user: jwtDecode(await getAccessTokenApi()),
      });
    }
  } else {
    setUser({
      isLoading: false,
      user: jwtDecode(accessToken),
    });
  }
};

export const AuthContext = createContext();

function AuthProvider(props) {
  const { children } = props;
  const [user, setUser] = useState({
    user: null,
    isLoading: true,
  });
  useEffect(() => {
    if (user.isLoading) {
      checkUserLogin(setUser);
    }
  }, [user]);

  const updateUserData = () => {
    setUser({
      isLoading: true,
      user: null,
    });
  };

  const mapChange = useCallback((response) => {
    return response;
  }, []);

  const {
    error: errorChangePass,
    isLoading: loadingPass,
    call: callChangePass,
  } = usePAppApi({
    url: '/user/change-password',
    mapResults: mapChange,
  });

  const changePassword = async (values) => {
    const response = await callChangePass({
      rut: user.user.rut,
      password: values.password,
      actual: values.actual,
    });
    return response;
  };
  console.log(user.user);

  const providerData = useMemo(
    () => ({
      ...user,
      user: {
        ...user?.user,
        is_administrador: user?.user?.type === 'administrador',
        is_ejecutivo: user?.user?.type === 'ejecutivo',
        is_liftlogic: user?.user?.type === 'liftlogic',
      },
      checkUserLogin,
      updateUserData,
      changePassword,
      loadingPass,
    }),
    [user]
  );

  return (
    <AuthContext.Provider value={providerData}>{children}</AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AuthProvider;
