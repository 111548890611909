import { PDFViewer } from '@react-pdf/renderer';
import React from 'react';
import PresupuestoDocument from './PresupuestoDocument';
import { useTenantData } from 'providers/Tenant/TenantDataProvider';

const PresupuestoViewer = ({ items, variablesMap, id }) => {
  const { colors, tenant } = useTenantData();
  return (
    <PDFViewer
      style={{
        width: '100%',
        height: '80vh',
      }}
    >
      <PresupuestoDocument
        variablesMap={variablesMap}
        items={items}
        colors={colors}
        config={tenant}
        id={id}
      />
      {/* <MaintanceDocument items={items} report={report} /> */}
    </PDFViewer>
  );
};

export default PresupuestoViewer;
