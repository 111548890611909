import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Form,
  Table,
  Space,
  message,
  notification,
  FloatButton,
  Modal,
} from 'antd';
import Title from 'antd/es/typography/Title';
import { CopyOutlined, SaveOutlined } from '@ant-design/icons';
import Compositor from 'components/CompositorDocumentos/Compositor';
import AscensorDisplayForm from 'components/forms/AscensorDisplayForm';
import useRequest from 'hooks/useRequest';
import { useParams } from 'react-router-dom';

const createAscensoresVariablesMap = (ascensores) => {
  return ascensores.reduce((acc, ascensor, index) => {
    const idx = index + 1;
    acc[`ascensor_${idx}_nombre`] = ascensor.nombre;
    acc[`ascensor_${idx}_marca`] = ascensor.marca;
    acc[`ascensor_${idx}_modelo`] = ascensor.modelo;
    acc[`ascensor_${idx}_capacidad`] = ascensor.capacidad;
    acc[`ascensor_${idx}_pisos`] = ascensor.pisos;
    acc[`ascensor_${idx}_estado`] = ascensor.estado;
    acc[`ascensor_${idx}_numero_serie`] = ascensor.numero_serie;
    return acc;
  }, {});
};

const columns = [
  {
    title: 'Campo',
    dataIndex: 'campo',
    key: 'campo',
  },
  {
    title: 'Valor',
    dataIndex: 'valor',
    key: 'valor',
  },
  {
    title: 'Clave',
    dataIndex: 'key',
    key: 'key',
    render: (text) => (
      <Space>
        <span>{`{${text}}`}</span>
        <Button
          icon={<CopyOutlined />}
          type="text"
          onClick={() => {
            navigator.clipboard.writeText(`{${text}}`);
            message.success('Clave copiada al portapapeles');
          }}
        ></Button>
      </Space>
    ),
  },
];

const transformClienteData = (cliente) => {
  const basicData = [
    { key: 'nombre_cliente', campo: 'Nombre', valor: cliente.name },
    { key: 'rut_cliente', campo: 'RUT', valor: cliente.rut },
    { key: 'direccion_cliente', campo: 'Dirección', valor: cliente.address },
    { key: 'comuna_cliente', campo: 'Comuna', valor: cliente.commune },
    {
      key: 'nombre_contacto',
      campo: 'Nombre de Contacto',
      valor: cliente.contactName,
    },
    { key: 'telefono_cliente', campo: 'Teléfono', valor: cliente.phone },
    { key: 'email_cliente', campo: 'Email', valor: cliente.email },
  ];

  // const ascensoresData = cliente.Ascensores
  //   ? transformAscensoresData(cliente.Ascensores)
  //   : [];

  // return basicData.concat(ascensoresData);
  return basicData;
};

const createVariablesMap = (cliente) => {
  const basicVariables = {
    nombre_cliente: cliente.name,
    rut_cliente: cliente.rut,
    direccion_cliente: cliente.address,
    comuna_cliente: cliente.commune,
    nombre_contacto: cliente.contactName,
    telefono_cliente: cliente.phone,
    email_cliente: cliente.email,
  };

  const ascensoresVariables = cliente.Ascensores
    ? createAscensoresVariablesMap(cliente.Ascensores)
    : {};

  return { ...basicVariables, ...ascensoresVariables };
};

const PresupuestosPage = () => {
  const [docForm] = Form.useForm();
  const [cliente, setCliente] = useState(null);
  const [variablesMap, setVariablesMap] = useState({});

  const { request, isLoading: isLoadingReq, errors } = useRequest();
  const [presupuesto, setPresupuesto] = useState({});
  const [doocumento, setDocumento] = useState({});
  const { id, documentId } = useParams();

  const getPresupuesto = useCallback(
    async (id) => {
      try {
        const result = await request(`/presupuestos/${id}/composer`, 'GET');
        setPresupuesto(result);
        setCliente(result.Cliente);
        setDocumento(result.Document);
        setVariablesMap(createVariablesMap(result.Cliente));
        return result;
      } catch (error) {
        notification.error({
          message: 'Error al cargar el presupuesto',
          description: error.message,
        });
      }
    },
    [request]
  );

  useEffect(() => {
    if (id) {
      getPresupuesto(id);
    }
  }, [id, getPresupuesto]);

  const saveDoc = async () => {
    try {
      await docForm.validateFields();
      const values = docForm.getFieldsValue();
      const response = await request(`/presupuestos/${id}/composer`, 'PUT', {
        items: values.items,
      });
    } catch (error) {
      notification.error({
        message: 'Error al guardar el presupuesto',
        description: error.message,
      });
    }
  };

  return (
    <div className="w-full md:w-1/2 space-y-5 mx-auto">
      <Card className="welcome mb-2">
        <div className="flex flex-col items-center md:flex-row md:justify-between">
          <Title level={3}>
            <span className="text-white">Generar nuevo presupuesto</span>
          </Title>
        </div>
      </Card>

      {cliente && (
        <Card>
          <Title level={4} className="!mt-0">
            Detalles del Cliente
          </Title>
          <Table
            columns={columns}
            dataSource={transformClienteData(cliente)}
            pagination={false}
            rowKey="key"
          />
          {cliente.Ascensores && (
            <div>
              <Title level={4} className="!mt-0">
                Ascensores
              </Title>
              <AscensorDisplayForm
                form={docForm}
                initialValues={{ ascensores: cliente.Ascensores }}
                onFinish={(values) => console.log('Ascensores:', values)}
                disabled
                layout="vertical"
              />
            </div>
          )}
        </Card>
      )}
      <Compositor
        presupuesto_id={id}
        form={docForm}
        variablesMap={variablesMap}
        documentElements={doocumento?.DocumentElements}
        cliente={cliente}
        availableModules={[
          {
            key: 'collection',
            label: 'De una Colección',
          },
          {
            key: 'itemTable',
            label: 'Tabla de Ítems',
          },
          {
            key: 'customerInfo',
            label: 'Información del Cliente',
          },
          {
            key: 'custom',
            label: 'Personalizado',
          },
          {
            key: 'image',
            label: 'Imagen',
          },
        ]}
      />
      <FloatButton
        type="primary"
        shape="circle"
        className="shadow-2xl shadow-black"
        onClick={saveDoc}
        icon={<SaveOutlined />}
      />
    </div>
  );
};

export default PresupuestosPage;
