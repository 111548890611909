import React from 'react';
import PresupuestoItemsTable from 'components/tables/PresupuestoItemsTable';

const ItemTableModule = ({ form, name }) => (
  <PresupuestoItemsTable
    items={form.getFieldValue(['items', name, 'itemsTable']) || []}
  />
);

export default ItemTableModule;
