import { Font } from '@react-pdf/renderer';
import React from 'react';
import AuthProvider from './providers/AuthProvider';
import './scss/index.scss';
import createHypenator from 'hyphen';
import patterns from 'hyphen/patterns/es';
import SecureRouter from 'SecureRouter';
import moment from 'moment';
import { App as AntdApp } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
dayjs.locale('es');

import 'moment/locale/es'; // Importar el locale español
import { TenantDataProvider } from 'providers/Tenant/TenantDataProvider';
// Establecer el locale de moment a español
moment.locale('es');

const text = createHypenator(patterns, {
  hyphenChar: '-',
  minWordLength: 0,
});

Font.registerHyphenationCallback((word) => {
  if (word.includes(' ')) {
    return [word];
  }
  return text(word).split('-');
});

function App() {
  return (
    <AntdApp>
      <AuthProvider>
        <TenantDataProvider>
          <SecureRouter />
        </TenantDataProvider>
      </AuthProvider>
    </AntdApp>
  );
}

export default App;
