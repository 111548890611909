import { getAccessTokenApi } from 'api/auth';
import { BASE_PATH_SERVER } from 'config';
import { useCallback, useEffect, useState } from 'react';
import { getApi, postApi, putApi } from '../../api';
import notificationApi from '../../utils/notificationApi';
import { deleteApi } from '../../api';

/**
 *
 * @param {Text} url - Url a la que se consultará
 * @param {*} defaultPayload - Retorno por defecto del payload
 * @returns - Hook
 */

export const useGetApi = (url, defaultPayload) => {
  const [payload, setPayload] = useState(defaultPayload || []);

  const fetchData = useCallback(() => {
    getApi(url).then((result) => {
      setPayload(result);
    });
  }, [url]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [payload, fetchData];
};

/**
 * @typedef {Array} ApiCallResult
 * @property {Array} 0 - Resultado de la llamada a la API
 * @property {Function} 1 - Función para refrescar los datos
 */

/**
 *
 * @param {Object} props - Debe contener los elementos para hacer la llamada a la api. como la URL
 * @param {Text} props.url - Contiene la url relativa a la que se hará la llamada
 * @param {Text} props.key - Debe contener la llave con la que la api devuelve el resultado de la llamada
 * @param {any} props.defaultValue - Debe contener el valor por defecto del resultado de la llamada. Por defecto null
 * @param {Bool} props.successEnabled - Opción de notificar si la api devuelve un estado success o no. Por defecto true
 * @param {Bool} props.defaultReload - Define si quieres que llame una vez se renderiza el componente
 * @param {Array} props.params - Parámetros de la petición
 * @returns {ApiCallResult} - Devuelve el hook personalizado
 */

export const useApi = (props) => {
  const {
    url,
    key,
    successEnabled = true,
    defaultValue = null,
    defaultReload = true,
    params = null,
  } = props;
  // payload almacena el resultado de la llamada
  const [payload, setPayload] = useState(defaultValue);

  // Reload indica si se debe volver a pedir los datos a la API
  const [reload, setReload] = useState(defaultReload);

  const callApi = useCallback(async () => {
    const response = await getApi(url, params);
    if (notificationApi(response, successEnabled)) {
      setPayload(response[key]);
    }
  }, [url, successEnabled, key, params]);

  useEffect(() => {
    if (reload) {
      callApi();
      setReload(false);
    }
  }, [reload, callApi]);
  // Función para refrescar los datos
  const refresh = () => {
    setReload(true);
  };
  return [payload, refresh, reload];
};

export const useCallGetApi = (props) => {
  const {
    url,
    defaultValue = {
      state: null,
      data: null,
      message: null,
    },
    config = null,
  } = props;

  const [payload, setPayload] = useState(defaultValue);

  // Reload indica si se debe volver a pedir los datos a la API
  const [isLoading, setIsLoading] = useState(false);

  const token = getAccessTokenApi();

  const call = useCallback(async () => {
    setIsLoading(true);
    fetch(`${BASE_PATH_SERVER}${url}`, {
      method: 'GET',
      headers: {
        Authorization: token,
      },
      ...config,
    })
      .then((response) => response.json())
      .then((result) => {
        setPayload(result);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        return {
          status: 'client',
          codeMessage: 'ERROR_EN_LA_PETICION',
          message: err.message,
        };
      });
  }, [url, token]);

  return [payload, isLoading, call];
};

/**
 * @typedef {Object} Config
 * @property {Text} url - Url a donde se hará la petición
 */

/**
 *
 * @param {Config} config - Configuración del hook
 * @returns - Devuelve la función post con el resultado de la llamada
 */
export const usePostApi = (config) => {
  const { url, successEnabled = true } = config;

  /**
   *
   * @param {Object} body - Elementos a enviar a la pi
   * @returns {Object} - Resultado de la petición
   */

  const post = (body) =>
    postApi(url, body).then((result) => {
      notificationApi(result, successEnabled);
      return result;
    });

  return [post];
};

export const usePutApi = (config) => {
  const { url, successEnabled = true } = config;

  /**
   *
   * @param {Object} body - Elementos a enviar a la pi
   * @param {Number} id - Id del elemento a modificar
   * @returns {Object} - Resultado de la petición
   */

  const put = (body, id) =>
    putApi(url, { ...body, elementId: id }).then((result) => {
      notificationApi(result, successEnabled);
      return result;
    });
  return [put];
};
/**
 *
 * @param {*} props
 * @param {string} props.url - URL de la API.
 * @param {string} [props.method='GET'] - Método HTTP a utilizar en la llamada.
 * @param {*} [props.defaultParams=null] - Parámetros por defecto para la llamada
 * @param {boolean} [props.defaultReload=false] - Indica si se debe recargar la llamada por defecto.
 * @param {Object} [props.options] - Opciones para personalizar el comportamiento del hook.
 * @param {Array} [props.options.payloadInState=[]] - Estados en los que se debe almacenar el resultado de la llamada.
 * @returns {Object} - Objeto con el resultado de la llamada, una función para refrescar los datos, una función para actualizar los parámetros y un indicador de si la llamada está en proceso.
 */

export const useAppApi = (props) => {
  const {
    url,
    method = 'GET',
    defaultValue = null,
    defaultParams = null,
    defaultReload = false,
    notification = false,
    options = {
      payloadInState: [],
    },
  } = props;

  // almacena el resultado de la llamada
  const [payload, setPayload] = useState(defaultValue);

  //Almacena los parámetros de la llamada
  const [params, setParams] = useState(defaultParams);

  //Indica si se debe volver a llamar a la api
  const [reload, setReload] = useState(defaultReload);

  //Indica si la llamada se está procesando
  const [isLoading, setIsLoading] = useState(true);

  /**
   * LLama a la API
   * @param {*} body - Cuerpo de la petición HTTP.
   */
  const call = useCallback(
    async (body) => {
      let response;
      switch (method) {
        case 'GET':
          response = await getApi(url, params);
          break;
        case 'PUT':
          return await putApi(url, body);
        case 'POST':
          return await postApi(url, body);
        case 'DELETE':
          return await deleteApi(url);
        default:
          response = await getApi(url, params);
      }
      if (
        notificationApi(response, notification) ||
        options.payloadInState.includes(response.state)
      ) {
        setPayload(response);
      }
      setIsLoading(false);
    },
    [url, params, options.payloadInState, method, notification]
  );

  useEffect(() => {
    if (reload) {
      setIsLoading(true);
      call();
      setReload(false);
    }
  }, [reload, call]);

  /**
   * Función para refrescar los datos
   */
  const refresh = useCallback(() => {
    setReload(true);
  }, []);
  /**
   * Función para actualizar los parámetros de la llamada a la API
   * @param {*} newParams - Nuevos parámetros para la llamada.
   */
  const updateParams = useCallback((newParams) => {
    setParams(newParams);
    setReload(true);
  }, []);
  return { payload, refresh, updateParams, isLoading, call };
};
