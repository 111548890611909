import React, { useState, useEffect } from 'react';

import { Button, Card, Modal, notification } from 'antd';
import ClientList from 'components/lists/ClientList';
import ClientForm, {
  addClient,
  deleteClient,
  desactivateClient,
  editClient,
} from 'components/forms/ClientForm';
import ClientFilter from 'components/filters/ClientFilter';
import EliminarForm from 'components/forms/EliminarForm';
import { PlusOutlined } from '@ant-design/icons';
import { useGetToApi } from 'hooks/useApi/useGetAxios';
import ProblemsClientList from 'components/lists/ProblemsClientList';
import Title from 'antd/es/typography/Title';

const defaultModalProps = {
  children: null,
  title: 'Error',
  open: false,
};

function Client() {
  const [modalProps, setModalProps] = useState(defaultModalProps);
  const [clients, isLoadingClients, callClients] = useGetToApi('/client/all', {
    config: {
      params: { whitProblems: true },
    },
    initialValues: {
      data: [],
    },
  });
  const [filteredClients, setFilteredClients] = useState([]);

  const successFinish = () => {
    callClients();
    setModalProps(defaultModalProps);
  };

  const openDeleteClient = async (client) => {
    setModalProps({
      open: true,
      children: (
        <EliminarForm
          title="Eliminar Cliente"
          id={client.id}
          desactivate={(id) => desactivateClient(id, successFinish)}
          deleteRegister={(id) => deleteClient(id, successFinish)}
        />
      ),
    });
  };

  const openEditClient = async (client) => {
    setModalProps({
      open: true,
      children: (
        <ClientForm
          buttonLabel="Editar"
          submitForm={(values) => editClient(values, client.id, successFinish)}
          formProps={{
            initialValues: client,
          }}
        />
      ),
      title: 'Editar Cliente',
    });
  };

  const openAddClient = () => {
    setModalProps({
      open: true,
      children: (
        <ClientForm
          buttonLabel="Agregar"
          submitForm={(values) => addClient(values, successFinish)}
        />
      ),
      title: 'Agregar Cliente',
    });
  };
  const getClientProblems = async (client) => {
    setModalProps({
      open: true,
      children: (
        <ProblemsClientList client={client} openEditClient={openEditClient} />
      ),
      title: 'ELEMENTOS FALTANTES DEL CLIENTE ',
    });
  };

  useEffect(() => {
    setFilteredClients(clients.data);
  }, [clients.data]);

  useEffect(() => {
    callClients();
  }, [callClients]);

  const handleActiveClient = (client) => {
    console.log(client);
    notification.info({
      message: 'Estamos probando',
    });
  };

  return (
    <div>
      <Card className="welcome">
        <div className="flex flex-row justify-between mb-3 ">
          <Title level={3}>
            <span className=" text-white">Clientes</span>
          </Title>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={openAddClient}
          >
            Agregar
          </Button>
        </div>
        <div>
          <ClientFilter
            clients={clients.data}
            setFilteredClients={setFilteredClients}
          />
        </div>
      </Card>
      <ClientList
        openEditClient={openEditClient}
        openDeleteClient={openDeleteClient}
        getClientProblems={getClientProblems}
        clients={filteredClients}
        isLoading={isLoadingClients}
        refresh={callClients}
      />
      <Modal
        {...modalProps}
        footer={false}
        onCancel={() => setModalProps(defaultModalProps)}
      />
    </div>
  );
}

export default Client;
