import React from 'react';
import { Table } from 'antd';

const PresupuestoItemsTable = ({ items }) => {
  console.log(items);
  const columns = [
    {
      title: 'Ascensor',
      dataIndex: 'ascensor',
      key: 'ascensor',
      render: (ascensores) => (
        <div className="flex flex-wrap justify-center">
          {ascensores.join(', ')}
        </div>
      ),
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Val.Unit',
      dataIndex: 'unitValue',
      key: 'unitValue',
      render: (value) => `$${value?.toFixed(0)}`,
    },
    {
      title: 'Cant.',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Total',
      render: (value) => {
        return `$${(value.unitValue * value.quantity).toFixed(0)}`;
      },
    },
  ];

  const totalNet = items.reduce(
    (sum, item) => sum + Number(item.unitValue) * Number(item.quantity),
    0
  );
  const IVA = totalNet * 0.19; // 19% IVA
  const total = totalNet + IVA;
  return (
    <div className="w-full">
      <Table
        dataSource={items.map((item, index) => ({ ...item, key: index }))}
        columns={columns}
        pagination={false}
      />
      <div className="flex justify-end mt-4">
        <div className="text-right">
          <p>
            <strong>Valor Neto:</strong> ${totalNet.toFixed(0)}
          </p>
          <p>
            <strong>IVA:</strong> ${IVA.toFixed(0)}
          </p>
          <p>
            <strong>Total:</strong> ${total.toFixed(0)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PresupuestoItemsTable;
