/* eslint-disable no-unused-vars */
import { Button, Card, Col, Row, Space, Table, TreeSelect } from 'antd';
import React, { useEffect, useState } from 'react';
import DocSelect from 'components/selects/DocSelect';
import ClienteSelect from 'components/selects/ClienteSelect';
import NFilter from 'components/filters/NFilter';
import whitDoc from 'components/HOCs/documentos/whitDoc';
import { useDocumentos } from 'providers/Administradores/DocumentosProvider';
import { useActionsPresupuestos } from 'providers/Administradores/ActionsPresupuestoProvider';
import { useActionsODT } from 'providers/ActionsOdtProvider';
import { useActionsComprobantes } from 'providers/ActionsComprobantesProvider';
import { useActionsMantencionesDep } from 'providers/ActionsMantencionDepProvider';
import { DownloadOutlined } from '@ant-design/icons';
import { useSolicitudesMantencion } from 'providers/SolicitudesMantencionProvider';
// import './Folders.scss';

function Folders() {
  const [doc, setDoc] = useState('presupuestos');
  const docChange = (value) => {
    setDoc(value);
  };
  const { call, data, addFilter } = useDocumentos();
  const { dowload: dowloadPresupuesto } = useActionsPresupuestos();
  const { dowload: dowloadODT } = useActionsODT();
  const { dowload: dowloadComprobante } = useActionsComprobantes();
  const { dowload: dowloadMantencionDep } = useActionsMantencionesDep();

  useEffect(() => {
    call(call);
  }, [call]);

  // Función que filtra la data por el edificio seleccionado
  const edificioFilter = (value) => {
    // Agregamos el filtro a la data.
    // Si el filtro es true (all) entonces eliminamos los filtros
    addFilter({
      idClient: value === true ? null : value,
    });
  };

  const numFilter = (value) => {
    // Agregamos el filtro a la data.
    // Si el filtro es true (all) entonces eliminamos los filtros
    addFilter({
      id: value === true ? null : value,
    });
  };

  const TableComponent = whitDoc(Table);

  const { openDowloadMantencion } = useSolicitudesMantencion();
  const handleClickInSolc = (record) => {
    openDowloadMantencion({
      id_mantencion: record.id,
    });
  };
  return (
    <Row gutter={5}>
      <Col span={6} xs={24} md={6} className="mb-2 md:mb-0">
        <Card className="h-full" title="Filtros">
          <Space direction="vertical" className="w-full" size="large">
            <DocSelect defaultValue={doc} onChange={docChange} />
            <ClienteSelect onChange={edificioFilter} />
            <NFilter onChange={numFilter} />
            {/* <TimeWindow /> */}
          </Space>
        </Card>
      </Col>
      <Col span={18} xs={24} md={18}>
        <Card className="h-full" title="Documentos">
          <TableComponent
            type={doc}
            data={data}
            customActions={{
              presupuestos: (presupuesto) => (
                <Space direction="horizontal">
                  <Button
                    type="primary"
                    onClick={() => dowloadPresupuesto(presupuesto.id)}
                    icon={<DownloadOutlined />}
                  ></Button>
                  {presupuesto.status === 'odt_finalizada' ? (
                    <Button
                      icon={<DownloadOutlined />}
                      onClick={() => dowloadODT(presupuesto.id)}
                    >
                      ODT
                    </Button>
                  ) : null}
                </Space>
              ),
              comprobantes: (comprobante) => (
                <Button
                  type="primary"
                  onClick={() => dowloadComprobante(comprobante.id)}
                  icon={<DownloadOutlined />}
                ></Button>
              ),
              mantencionesDep: (mantencionDep) => (
                <Button
                  type="primary"
                  onClick={() => dowloadMantencionDep(mantencionDep.id)}
                  icon={<DownloadOutlined />}
                ></Button>
              ),
              mantenciones: (mantencion) => (
                <Button
                  type="primary"
                  onClick={() => handleClickInSolc(mantencion)}
                  icon={<DownloadOutlined />}
                ></Button>
              ),
            }}
          />
        </Card>
      </Col>
    </Row>
  );
}

export default Folders;
