import { useAppApi } from 'hooks/useAppApi';
import { createContext, useCallback, useContext, useEffect } from 'react';

const TenantDataContext = createContext(null);

export const TenantDataProvider = ({ children }) => {
  const map = useCallback((result) => {
    return result;
  }, []);

  const {
    data,
    isLoading: loading,
    error,
    hasCalled,
    call,
  } = useAppApi({
    baseUrl: '/tenants/get-tenant-config',
    mapResults: map,
  });

  useEffect(() => {
    if (!hasCalled) {
      call();
    }
  }, [call, hasCalled]);
  return (
    <TenantDataContext.Provider
      value={{
        call,
        loading,
        error,
        hasCalled,
        menu: data?.menu,
        clientes: data?.clientes,
        tenant: data?.tenantData,
        colors: {
          primary: '#9c1415',
          secondary: '#b44c54',
        },
      }}
    >
      {children}
    </TenantDataContext.Provider>
  );
};

export const useTenantData = () => useContext(TenantDataContext);
