import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';

const ComposerFooter = ({ config, colors }) => {
  console.log(config);
  console.log(colors);

  const styles = StyleSheet.create({
    footer: {
      position: 'absolute',
      bottom: 20,
      left: 0,
      right: 0,
      textAlign: 'center',
      fontSize: 10,
      color: colors.primary ?? '#4CAF50',
    },
    companyName: {
      fontSize: 12,
      fontWeight: 'bold',
    },
    address: {
      fontSize: 10,
      color: 'gray',
    },
    email: {
      fontSize: 10,
      color: 'gray',
      textDecoration: 'underline',
    },
    phone: {
      fontSize: 10,
      color: 'gray',
    },
  });
  return (
    <View style={styles.footer} fixed>
      <Text style={styles.companyName}>{config.organization}</Text>
      <Text style={styles.address}>{config.direccion}</Text>
      <Text style={styles.email}>{config.web}</Text>
      <Text style={styles.phone}>{config.telefono}</Text>
    </View>
  );
};

export default ComposerFooter;
