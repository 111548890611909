import React, { useEffect } from 'react';
import { Avatar, Button, Card, Input, Typography } from 'antd';
import {
  RightOutlined,
  UserOutlined,
  SearchOutlined,
  CalendarOutlined,
  AppstoreOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import useAuth from 'hooks/useAuth';
import moment from 'moment';
import 'moment/locale/es';
import { Link } from 'react-router-dom';
import SolicitudesTable from 'components/tables/SolicitudesTable';
import SolicitudesForDayTable from 'components/tables/SolicitudesForDayTable';
import CalendarSolc from 'components/CalendarSolc';
import { useDashboard } from 'providers/DashboardProvider';
moment.locale('es');
const { Title } = Typography;

function Home() {
  const { user } = useAuth();
  const dayOfWeek = moment().format('dddd');
  const { data, isLoading, error, hasCalled, call } = useDashboard();
  useEffect(() => {
    if (!hasCalled) call();
  }, [call, hasCalled]);
  console.log(data);

  const solicitudesColors = {
    asignadas: 'border-blue-400',
    enCurso: 'border-orange-400',
    finalizadas: 'border-green-400',
    enviadas: 'border-purple-400',
    sinAsignar: 'border-gray-400',
  };

  return (
    <div className="pt-5 grid grid-cols-3 gap-4">
      {/* Barra de búsqueda */}
      <div className="col-span-3 flex">
        <Input
          size="large"
          prefix={<SearchOutlined />}
          placeholder="No disponible por el momento"
          className="flex-grow"
          disabled
        />
        <Button icon={<UserOutlined />} className="ml-4">
          Mi Perfil
        </Button>
      </div>

      {/* Tarjeta de saludo */}
      <Card className="col-span-3 welcome text-white">
        <div className="flex flex-col justify-center items-center md:flex-row md:justify-between">
          <div className="text-lg">
            <div className="uppercase">!Buen día, {user.fullname}!</div>
            <div>Ten un excelente {dayOfWeek}!</div>
          </div>
          <Avatar className="mt-3" size={64} icon={<UserOutlined />} />
        </div>
      </Card>

      {/* Tarjetas de trabajo */}
      <Card className="col-span-3 md:col-span-1" loading={isLoading}>
        <div className="flex flex-row justify-between">
          <div>
            <p className="font-bold text-xl">NÚMERO DE CLIENTES</p>
            <p className="font-extrabold text-5xl">{data?.clientes}</p>
          </div>
          <AppstoreOutlined className="text-5xl text-blue-400" />
        </div>
        <div>
          <Link to={'/admin/client'}>
            <Button
              className="!border-blue-400 !border-1 !rounded-md"
              type="link"
              icon={<RightOutlined />}
            >
              IR A CLIENTES
            </Button>
          </Link>
        </div>
      </Card>
      <Card className="col-span-3 md:col-span-1" loading={isLoading}>
        <div className="flex flex-row justify-between">
          <div>
            <p className="font-bold text-xl"> NÚMERO DE USUARIOS</p>
            <p className="font-extrabold text-5xl">{data?.users}</p>
          </div>
          <UserOutlined className="text-5xl text-blue-400" />
        </div>
        <div>
          <Link to={'/admin/users'}>
            <Button
              className="!border-blue-400 !border-1 !rounded-md"
              type="link"
              icon={<RightOutlined />}
            >
              IR A USUARIOS
            </Button>
          </Link>
        </div>
      </Card>
      <Card className="col-span-3 md:col-span-1" loading={isLoading}>
        <div className="flex flex-row justify-between">
          <div>
            <p className="font-bold text-xl"> NÚMERO DE ADMINISTRADORES</p>
            <p className="font-extrabold text-5xl">{data?.adminClientes}</p>
          </div>
          <TeamOutlined className="text-5xl text-blue-400" />
        </div>
        <div>
          <Link to={'/admin/administradores'}>
            <Button
              className="!border-blue-400 !border-1 !rounded-md"
              type="link"
              icon={<RightOutlined />}
            >
              IR A ADMINISTRADORES
            </Button>
          </Link>
        </div>
      </Card>
      <Card className="welcome col-span-3">
        <Title level={3} className=" text-start">
          <span className="uppercase text-white">
            Solicitudes de Mantención
          </span>
        </Title>
      </Card>

      <div className="col-span-3 grid grid-cols-1 md:grid-cols-5 gap-4">
        <Card
          className={`text-blue-600 text-xl font-bold border-2 ${solicitudesColors.asignadas}`}
        >
          <p>
            <span className="text-blue-600 text-base uppercase">
              Solicitudes Asignadas:
            </span>{' '}
            {data?.solicitudes?.asignadas}
          </p>
        </Card>
        <Card
          className={`text-orange-600 text-xl font-bold border-2 ${solicitudesColors.enCurso}`}
        >
          <p>
            <span className="text-orange-600 text-base uppercase">
              Solicitudes en Curso:
            </span>{' '}
            {data?.solicitudes?.enCurso}
          </p>
        </Card>
        <Card
          className={`text-green-600 text-xl font-bold border-2 ${solicitudesColors.finalizadas}`}
        >
          <p>
            <span className="text-green-600 text-base uppercase">
              Solicitudes Finalizadas:
            </span>{' '}
            {data?.solicitudes?.finalizadas}
          </p>
        </Card>
        <Card
          className={`text-purple-600 text-xl font-bold border-2 ${solicitudesColors.enviadas}`}
        >
          <p>
            <span className="text-purple-600 text-base uppercase">
              Solicitudes Enviadas:
            </span>{' '}
            {data?.solicitudes?.enviadas}
          </p>
        </Card>
        <Card
          className={`text-gray-600 text-xl font-bold border-2 ${solicitudesColors.sinAsignar}`}
        >
          <p>
            <span className="text-gray-600 text-base uppercase">
              Solicitudes Sin Asignar:
            </span>{' '}
            {data?.solicitudes?.sinAsignar}
          </p>
        </Card>
      </div>

      <div className="col-span-3 md:col-span-1">
        <div className="flex flex-col justify-between space-y-2 h-full">
          <Card
            title="Solicitudes"
            className="flex-1"
            styles={{
              body: {
                padding: 10,
              },
            }}
          >
            <SolicitudesTable />
          </Card>
          <SolicitudesForDayTable />
        </div>
      </div>

      {/* Calendario */}
      <Card
        className="col-span-3 md:col-span-2"
        title={
          <>
            <CalendarOutlined className="text-xl" /> Calendario de Mantenciones
          </>
        }
      >
        <CalendarSolc />
      </Card>
    </div>
  );
}

export default Home;
